$sidebar-width: 200px;
$sidebar-bg-color: #fff;
$sidebar-color: #000;
$submenu-bg-color: #f1f1f1;
$submenu-bg-color-collapsed: #141414;
$highlight-color: #b75cff;

@import "~react-pro-sidebar/dist/scss/styles.scss";

.ps-sidebar {
  overflow: auto;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
  flex: 0 0 200px;
  max-width: 200px;
  min-width: 200px;
  width: 200px;
  height: 100%;
  transition: all 0.2s;
  .nav-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 80px;
    box-sizing: border-box;
    height: 100%;
    .main-sidebar {
      overflow-y: auto;
      flex-grow: 1;
      .tab-active {
        border-right: 3px solid rgba(0, 0, 0, 0.7);
        background: rgba(0, 0, 0, 0.3);

        // a {
        //   // color: #b75cff;
        // }
      }
      .pro-sub-menu {
        .react-slidedown {
          padding: 0;
          .pro-menu-item {
            > div {
              padding: 8px 35px 8px 60px;
            }
          }
        }
      }
      .pro-icon-wrapper {
        background-color: transparent !important;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
    .bottom-sidebar {
      padding: 8px 20px;
      display: flex;
      flex-direction: column;
      background-color: $sidebar-bg-color;
      color: $sidebar-color;
      .dfs-token {
        display: flex;
        align-items: center;
        img {
          border-radius: 50%;
          margin-right: 5px;
        }
        .token-name {
          flex: 1 1;
        }
      }
    }
  }
}

.ps-content {
  background-color: #faf9fa;
  margin-left: 200px;
  padding: 80px 20px 20px;
  transition: all 200ms linear 0s;
  @media (max-width: 640px) {
    margin: 0;
  }
  > div {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    min-height: 80vh;
    .container {
      padding: 10px;
    }
  }

  .ps-warn {
    min-height: auto;
    padding: 30px 0;
    @media (max-width: 640px) {
      padding: 20px 0;
    }
    .container {
      p {
        font-size: 14px;
        width: 70%;
        margin: 0 auto;
        text-align: center;
        @media (max-width: 640px) {
          width: 100%;
          font-size: 12px;
        }
      }
    }
  }

  .ps-home {
    .section-hero {
      text-align: center;
      padding: 3rem 0 1.5rem;
      .hero-title {
        margin-bottom: 20px;
      }
      .hero-desc {
        width: 70%;
        margin: 0 auto 20px auto;
      }
      .hero-button-group {
        margin: 0 auto;
        padding: 1.5rem 0;
        button {
          font-size: 20px;
          min-width: 150px;
          background: #fff;
          border: 1px solid #000 !important;
          color: #000;
          @media (max-width: 640px) {
            font-size: 14px;
            min-width: 120px;
          }
        }
        button:hover {
          background: #000;
          color: #fff;
        }
        .upload-btn {
          margin-right: 20px;
        }
      }
      .hero-statistic {
        display: grid;
        grid-template-columns: repeat(4, auto);
        gap: 20px;
        padding: 1.5rem 0 3rem;
        @media (max-width: 640px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 525px) {
          grid-template-columns: repeat(1, 1fr);
        }
        .card-component {
          background: #fff;
          padding: 40px;
          box-shadow: 0 0 1px rgb(0 0 0 / 1%), 0 4px 8px rgb(0 0 0 / 1%),
            0 8px 12px rgb(0 0 0 / 1%), 0 12px 16px rgb(0 0 0 / 1%);
          @media (max-width: 640px) {
            padding: 20px;
          }
          .nav-card {
            text-align: center;
            word-break: break-word;
          }
        }
      }
    }
    .section-intro {
      text-align: center;
      padding: 3rem 0 1.5rem;
      .hero-title {
        margin-bottom: 20px;
      }
      .hero-desc {
        width: 70%;
        margin: 0 auto 20px auto;
      }
      .hero-statistic {
        display: grid;
        grid-template-columns: repeat(4, auto);
        gap: 20px;
        padding: 1.5rem 0 3rem;
        @media (max-width: 640px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 525px) {
          grid-template-columns: repeat(1, 1fr);
        }
        .card-component {
          background: #fff;
          padding: 40px;
          box-shadow: 0 0 1px rgb(0 0 0 / 1%), 0 4px 8px rgb(0 0 0 / 1%),
            0 8px 12px rgb(0 0 0 / 1%), 0 12px 16px rgb(0 0 0 / 1%);
          @media (max-width: 640px) {
            padding: 20px;
          }
          .nav-card {
            word-break: break-word;
            text-align: center;
            h1 {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  .ps-create {
    .section-hero {
      text-align: center;
      padding: 3rem 0 1.5rem;
      .hero-title {
        margin-bottom: 20px;
      }
      .hero-desc {
        width: 70%;
        margin: 0 auto 20px auto;
      }
    }
    .section-form {
      .card-component {
        border-radius: 4px;
        background: #fff;
        padding: 20px;
        border: 1px solid #f0f0f0;
        .card-container {
          .nav-section {
            padding: 15px 0;
            .column {
              padding: 5px 0;
              .rmdp-container {
                display: block !important;
              }
              .item-title {
                font-weight: bold;
                padding: 8px 0;
              }
              .item-desc {
                padding: 5px 0;
                font-size: 12px;
              }
              .warn-item {
                font-size: 12px;
                color: red;
              }
              .text-input,
              .rmdp-input,
              .number-input {
                padding: 8px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                border-radius: 0;
                outline: none;
                width: 100%;
                height: auto;
                margin: 0;
              }
            }
            .row {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 20px;
              @media (max-width: 640px) {
                grid-template-columns: repeat(1, auto);
                gap: 0px;
              }
            }
          }
          .token-info,
          .addtional-info,
          .more-info {
            .column {
              .row {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                padding: 8px 0;
              }
              .grid {
                display: flex;
                @media (max-width: 500px) {
                  justify-content: space-between;
                }
                label {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  input {
                    margin: 0 10px 0 0;
                  }
                  @media (min-width: 500px) {
                    min-width: 100px;
                  }
                }
              }
            }
          }
          .submit-btn {
            text-align: center;
          }
        }
      }
    }
  }

  .ps-list {
    .section-hero {
      text-align: center;
      padding: 3rem 0 1.5rem;
      .hero-title {
        margin-bottom: 20px;
      }
      .hero-desc {
        width: 70%;
        margin: 0 auto 20px auto;
      }
    }
    .section-list {
      .nav-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        @media (max-width: 640px) {
          grid-template-columns: repeat(2, auto);
        }
        @media (max-width: 550px) {
          grid-template-columns: repeat(1, auto);
        }
        .card-component {
          border: 1px solid #f0f0f0;
          background: #fff;
          .card-container {
            padding: 20px;
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px 0;
              .time {
                font-size: 14px;
                font-weight: 600;
                .sale-status {
                  padding-bottom: 5px;
                }
                .time-box {
                  display: flex;
                  gap: 5px;
                  div {
                    border-radius: 5px;
                    background: #1abc13;
                    padding: 7px;
                    color: #fff;
                    min-width: 35px;
                    text-align: center;
                  }
                }
              }
              .logo {
                height: 50px;
                background-position: center;
                background-size: cover;
                img {
                  border-radius: 50%;
                  width: 100%;
                  height: 100%;
                }
              }
              .status {
                color: #fff;
                min-width: 50px;
                border-radius: 20px;
                background: #b75cff;
                padding: 3px 10px;
                span {
                  font-size: 14px;
                }
              }
            }
            .name {
              display: flex;
              flex-direction: column;
              padding: 10px 0;
              .tokenName {
                font-weight: bold;
                font-size: 24px;
              }
              .presaleInfoUrl {
                padding: 5px 0;
                display: flex;
                gap: 10px;
                .social-icon {
                  img {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
            .cap {
              padding: 10px 0;
              display: flex;
              flex-direction: column;
              .cap-title {
                font-size: 14px;
              }
              .cap-value {
                font-size: 16px;
                font-weight: bold;
                color: #1abc13;
              }
            }
            .progress {
              padding: 10px 0;
              display: flex;
              flex-direction: column;
              .title {
                display: flex;
                justify-content: space-between;
                span {
                  font-size: 14px;
                  font-weight: 600;
                }
              }
              .value {
                display: flex;
                justify-content: space-between;
                span {
                  font-size: 14px;
                }
              }
              .bar {
                padding: 5px 0;
              }
            }
            .footer {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              border-top: 1px solid #f0f0f0;
              padding-top: 20px;
              a {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .ps-detail {
    padding: 50px 0;
    .container {
      display: flex;
      gap: 30px;
      @media (max-width: 640px) {
        flex-direction: column;
      }
      .left {
        width: 65%;
        border: 1px solid #f0f0f0;
        padding: 20px;
        background: #fff;
        @media (max-width: 640px) {
          width: 100%;
        }
      }
      .right {
        width: 35%;
        @media (max-width: 640px) {
          width: 100%;
        }
        > div {
          margin-bottom: 20px;
          padding: 20px;
          border: 1px solid #f0f0f0;
          background: #fff;
        }
        > div:last-child {
          margin-bottom: 0;
        }
        .status-box {
          .time {
            padding: 10px 0;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            .sale-status {
              padding: 5px 0;
            }
            .time-box {
              display: flex;
              justify-content: center;
              gap: 10px;
              div {
                border-radius: 5px;
                background: #1abc13;
                padding: 7px;
                min-width: 35px;
                color: #fff;
              }
            }
          }
          .progress {
            padding: 10px 0;
            .status {
              display: flex;
              justify-content: space-between;
              padding: 5px 0;
              font-weight: 600;
              font-size: 14px;
            }
          }
          .buy {
            padding: 10px 0;
            text-align: center;
            .title {
              font-weight: 600;
              padding: 5px 0;
              text-align: left;
            }
            .number-input {
              padding: 7px;
              border: 1px solid rgba(0, 0, 0, 0.2);
              outline: none;
              width: 100%;
            }
            .warn-item {
              font-size: 12px;
              color: red;
              text-align: left;
            }
            .currentBalance {
              text-align: left;
              font-size: 14px;
            }
            button {
              margin-top: 20px;
            }
          }
        }
        .client-box {
          .row {
            padding: 10px 0;
            border-bottom: 1px solid #f0f0f0;
            display: flex;
            justify-content: space-between;
            span {
              font-size: 14px;
            }
          }
        }
        .owner-box {
          .nav-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            .title {
              font-weight: 600;
              font-size: 24px;
            }
            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.ps-fullcontent {
  margin-left: 0px;
}
